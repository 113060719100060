import router from "../../router";
import { getMe } from "@/api/users.api";
import { userCalendarIsEnabled } from "@/helpers/user.helper";

export default {
  namespaced: true,
  state: () => ({
    user: null,
    doppelgangerMode: false,
    doppelganger: null,
    accessToken: null,
    refreshToken: null,
    userInitialStatus: {
      linkedinAccountsStatus: "MISSING", // "ACTIVE" | "REQUIRES_ACTION" | "CREATED" | "ERROR";
      senderStatus: "MISSING", // "ERROR" | "ACTIVE" | "CREATED";
      meetingLinkStatus: "MISSING",
    },
  }),
  getters: {
    isLoggedIn(state) {
      return state.user !== null;
    },
    isDevTeam(state) {
      return (
        state.user !== null &&
        Array.isArray(state.user.functions) &&
        state.user.functions.includes("DEV")
      );
    },
    isCustomerSuccess(state) {
      return (
        state.user !== null &&
        Array.isArray(state.user.functions) &&
        state.user.functions.includes("CUSTOMER_SUCCESS")
      );
    },
    isAdmin(state) {
      return (
        state.user != null &&
        (state.user.role === "ADMIN" ||
          state.user.role === "SUPER_ADMIN" ||
          state.user.role === "OWNER" ||
          state.user.role === "OWNER_ONLY")
      );
    },
    getMeetingBookingURL(state) {
      return state.user != null && state.user.meetingBookingURL
        ? state.user.meetingBookingURL
        : undefined;
    },
    isSuperAdmin(state) {
      return state.user != null && state.user.role === "SUPER_ADMIN";
    },
    isOwnerOnly(state) {
      return state.user != null && state.user.role === "OWNER_ONLY";
    },
    isRegularUser(state) {
      return state.user != null && state.user.role === "USER";
    },
    isOrganizationLocked(state) {
      return state.user != null && state.user.organizationLocked;
    },
    getOrganizationLockReason(state) {
      return state.user != null && state.user.organizationLockReason
        ? state.user.organizationLockReason
        : "";
    },
    getUser(state) {
      return state.user;
    },
    getTokens(state) {
      return {
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
      };
    },
    getUserInitialStatus(state) {
      return state.userInitialStatus;
    },
    userHasAccessToApp(state) {
      const user = state.user;
      return user !== null && user["setupWizardCompleted"] === true;
    },
    /**
     * Computed to check if user does not have any grants
     * @returns {boolean}
     */
    userHasCalendarEnabled(state) {
      return userCalendarIsEnabled(state.user);
    },
  },
  mutations: {
    clearUser(state) {
      state.user = null;
    },
    setUser(state, user) {
      state.user = user;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    setUserInitialStatus(state, data) {
      state.userInitialStatus = {
        linkedinAccountsStatus: data.linkedinAccountsStatus,
        senderStatus: data.senderStatus,
        meetingLinkStatus: data.meetingLinkStatus,
      };
    },
  },
  actions: {
    async fetchUserData({ commit, dispatch }) {
      const { user, status } = await getMe();

      if (user && status) {
        commit("setUser", user);
        commit("setUserInitialStatus", {
          linkedinAccountsStatus: status.linkedinAccountsStatus,
          senderStatus: status.senderStatus,
          meetingLinkStatus: status.meetingLinkStatus,
        });
      } else {
        await dispatch("logout");
      }
    },
    async setUser({ commit }, user) {
      commit("setUser", user);
    },
    setTokensInLocalStorage({ commit }, tokens) {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (typeof accessToken == "string") {
        localStorage.removeItem("accessToken");
      }

      if (typeof refreshToken == "string") {
        localStorage.removeItem("refreshToken");
      }

      localStorage.setItem("accessToken", tokens.accessToken);
      localStorage.setItem("refreshToken", tokens.refreshToken);

      commit("setAccessToken", tokens.accessToken);
      commit("setRefreshToken", tokens.refreshToken);
    },
    setTokensFromLocalStorage({ commit }) {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (!!accessToken && !!refreshToken) {
        commit("setAccessToken", accessToken);
        commit("setRefreshToken", refreshToken);
      }
    },
    /**
     * Logout from the app
     * @param commit
     * @returns {Promise<void>}
     */
    async logout({ commit }) {
      commit("clearUser");

      const localStorageKeysToDelete = ["accessToken", "refreshToken", "user"];
      for (const key of localStorageKeysToDelete) {
        localStorage.removeItem(key);
      }

      await router.push("/login");
    },
  },
};
