/**
 * Check if user grants contain zoom entry
 * @param {object} user
 * @returns {boolean}
 */
export function userGrantsHasZoom(user) {
  if (
    typeof user === "undefined" ||
    user === null ||
    !Array.isArray(user.nylasGrants) ||
    (Array.isArray(user.nylasGrants) && user.nylasGrants.length === 0)
  ) {
    return false;
  }

  return user.nylasGrants.some((grant) => grant.provider === "zoom");
}

/**
 * Extract calendar provider from user document
 * @param {object} user
 * @returns {undefined|"microsoft"|"google"}
 */
export function getUserCalendarProvider(user) {
  if (
    (typeof user !== "undefined" &&
      user !== null &&
      !Array.isArray(user.nylasGrants)) ||
    !user ||
    (Array.isArray(user.nylasGrants) && user.nylasGrants.length === 0)
  ) {
    return undefined;
  }

  const calendarProvider = user.nylasGrants.filter(
    (grant) => grant.provider !== "zoom"
  );

  if (calendarProvider.length === 0) {
    return undefined;
  }

  return calendarProvider[0].provider;
}

/**
 * Returns selected calendar (if found) from user document
 * @returns {undefined | {id: string; name: string; grantId: string; timezone: string; hexColor: string; hexForegroundColor: string; }}
 */
export function getUserSelectedCalendar(user) {
  if (
    typeof user !== "undefined" &&
    user !== null &&
    user.calendar &&
    user.calendar.selected
  ) {
    return user.calendar.selected;
  }

  return undefined;
}

/**
 * Check if user has required grants to perform action on calendar
 * @param user
 * @returns {boolean}
 */
export function userCalendarIsEnabled(user) {
  if (typeof user === "undefined" || user === null) {
    return false;
  }

  const checks = [
    // User has selected calendar, it can't be undefined
    typeof user.calendar?.selected !== "undefined",
    // Settings object is defined in user calendar settings array
    Array.isArray(user.calendar?.settings),
    // At least one grant is "calendar" type
    user.nylasGrants?.some(
      (grant) => grant.provider === "google" || grant.provider === "microsoft"
    ) ?? false,
    // Selected calendar is from one of the Nylas grants
    user.nylasGrants?.some(
      (grant) => grant.grantId === user.calendar?.selected?.grantId
    ) ?? false,
  ];

  return checks.every((check) => check);
}
